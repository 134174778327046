import { ITicketMeta, IServiceMeta } from 'schema'

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init)
  return res.json()
}

export async function arrayFetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init)
  let resJson = await res.json()
  if (Array.isArray(resJson?.data)) {
    const parsedIdArray = resJson.data.reduce(
      (acc: any, cur: ITicketMeta | IServiceMeta) => ({
        ...acc,
        [cur._id]: cur,
      }),
      {}
    )
    resJson = { ...resJson, parsedIdArray }
  }

  return resJson
}

export const getFetcher = async <JSON extends any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> => {
  const res = await fetch(input, init)
  return res.json()
}

export const postFetcher = async (
  url: string,
  { arg }: { arg: { service: string } }
) => {
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arg),
  })
}

export const fetchPostWithToken = async (
  url: string,
  jwt: string | null | undefined,
  { arg }: any
) => {
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt || '',
    },
    method: 'POST',
    body: JSON.stringify(arg),
  }).then(res => res.json())
}

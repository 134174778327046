import { useEffect, useRef, useState } from 'react'
import '../App.css'
import { TicketList } from './TicketList'
import {
  useApiCurrentDateTickets,
  useApiTicketIsCalledUpdate,
  useApiServices,
  useApiCounters,
} from '../hooks/useApi'
import Footer from './Footer'
import QantasLogo from '../assets/images/qantas_logo.png'

const callText = (speechText: string) => {
  let speech = new SpeechSynthesisUtterance()
  speech.volume = 1
  speech.rate = 0.6
  // 2 is the highest standard pitch value in the Web Speech API
  // Values outside 0-2 range may not be supported in all browsers
  speech.pitch = 2
  speech.text = speechText

  // Get available voices - ensure we have loaded voices
  let voices = window.speechSynthesis.getVoices()
  if (voices.length === 0) {
    // If voices aren't loaded yet, wait for them
    window.speechSynthesis.addEventListener(
      'voiceschanged',
      () => {
        voices = window.speechSynthesis.getVoices()
        speech.voice = voices[0] || null
        window.speechSynthesis.speak(speech)
      },
      { once: true }
    )
  } else {
    speech.voice = voices[0] || null
    window.speechSynthesis.speak(speech)
  }
}

const TicketQueue = () => {
  const previousTicketsRef = useRef<Set<string>>(new Set())
  const [isProcessing, setIsProcessing] = useState(false)

  // Remove the argument from the hook call
  const { data: ticketsRes, mutate: refreshTickets } =
    useApiCurrentDateTickets()
  const tickets = ticketsRes?.data || []
  const { data: servicesRes } = useApiServices()
  const parsedServices = servicesRes?.parsedIdArray || {}
  const { data: countersRes } = useApiCounters()
  const parsedCounters = countersRes?.parsedIdArray || {}
  const { trigger: triggerTicketIsCalledUpdate } = useApiTicketIsCalledUpdate()

  // Set up manual polling
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshTickets()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [refreshTickets])

  useEffect(() => {
    if (!ticketsRes?.data) return

    // Only look for tickets that were just assigned (new acceptances)
    const newlyAcceptedTickets = tickets.filter(
      ticket =>
        ticket.ticketStatus === 'accepted' &&
        !ticket.isCalled &&
        !previousTicketsRef.current.has(ticket._id)
    )

    // Update tracking of seen tickets
    newlyAcceptedTickets.forEach(ticket => {
      previousTicketsRef.current.add(ticket._id)
    })

    // Only process the most recently accepted ticket
    if (newlyAcceptedTickets.length > 0) {
      setIsProcessing(true)
      const latestTicket = newlyAcceptedTickets[newlyAcceptedTickets.length - 1]

      if (!latestTicket) return

      const { service, incrementId, assignedCounter = '' } = latestTicket

      if (assignedCounter) {
        const ticketName = `${
          parsedServices[service.toString()]?.code || ''
        }${incrementId}`
        const counterName = parsedCounters[assignedCounter]?.name || ''
        const speechText = `ticket ${incrementId} to counter ${counterName}`

        callText(speechText)

        // @ts-ignore
        triggerTicketIsCalledUpdate({
          ticketId: latestTicket._id,
          isCalled: true,
        }).then(() => {
          setIsProcessing(false)
          // Force refresh tickets data after updating
          refreshTickets()
        })
      }
    }
  }, [
    tickets,
    parsedServices,
    parsedCounters,
    triggerTicketIsCalledUpdate,
    refreshTickets,
  ])

  return (
    <div className='flex flex-col h-screen w-screen'>
      <div className='flex items-center justify-center'>
        <img src={QantasLogo} alt='Qantas' width={700} height={200} />
      </div>
      <div className='flex flex-col lg:flex-row flex-1 min-h-0'>
        <div className='flex flex-col items-center w-2/4 overflow-auto shrink-0 border-r-2 border-gray-900'>
          <TicketList title='Missed Queue' ticketStatus='skipped' />
        </div>

        <div className='flex flex-col items-center w-3/5 overflow-auto'>
          <TicketList title='Now Serving' ticketStatus='accepted' />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TicketQueue

import { useEffect, useRef, useCallback } from 'react'
import '../App.css'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { TicketPrintComponent } from './TicketPrintComponent'
import { useStore } from '../hooks/useStore'
import { useApiServices } from '../hooks/useApi'

export const Confirmation = () => {
  const newTicket = useStore(state => state.newTicket)
  const { data: servicesRes } = useApiServices()
  const parsedServices = servicesRes?.parsedIdArray || {}
  const componentRef = useRef(null)
  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [])

  const ref = useRef(null)
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
  })

  useEffect(() => {
    handlePrint(null)
    const timer = setTimeout(() => {
      window.location.reload()
    }, 5000)
    return () => clearTimeout(timer)
  }, [handlePrint])

  return (
    <div className='flex flex-col'>
      <div className='flex-grow'>
        <div className='flex flex-col items-center justify-center gap-6 w-full'>
          <div className='flex flex-col gap-4 items-center'>
            <p className='text-red-primary font-semibold text-[55px]'>
              Receipt Confirmation
            </p>

            <p className='text-lg lg:text-3xl font-semibold text-center'>
              Thank you! We look forward to assisting you.
            </p>
          </div>

          <div className='FacilitiesBox2 FacilitiesBox w-[400px] lg:w-[600px]'>
            <ul className='flex items-center justify-center text-center'>
              <li style={{ width: '100%' }}>
                <Link
                  to=''
                  style={{ padding: '40px', display: 'none' }}
                  id='trigger'
                  ref={ref}
                  onClick={handlePrint}
                  className='Button No'
                >
                  Print
                </Link>
                <a
                  href='/ticket'
                  style={{ padding: '40px' }}
                  className='Button No'
                >
                  BACK
                </a>
              </li>
            </ul>

            <div style={{ display: 'none' }}>
              {
                // TODO: Improve PrintComponent
              }
              <TicketPrintComponent
                ref={componentRef}
                ticket={{
                  ticketType:
                    parsedServices[newTicket?.service.toString() || '']?.code ||
                    '',
                  ticket: newTicket?.incrementId.toString() || '',
                  service:
                    parsedServices[newTicket?.service.toString() || '']?.name ||
                    '',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
